@font-face {
  font-family: "Lato";
  src: url("./../../fonts/lato/Lato-Black.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../../fonts/lato/Lato-Bold.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../../fonts/lato/Lato-Italic.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../../fonts/lato/Lato-BlackItalic.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../../fonts/lato/Lato-BoldItalic.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../../fonts/lato/Lato-LightItalic.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../../fonts/lato/Lato-Regular.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("./../../fonts/lato/Lato-Hairline.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-Hairline.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("./../../fonts/lato/Lato-HairlineItalic.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-HairlineItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("./../../fonts/lato/Lato-Light.woff2") format("woff2"),
    url("./../../fonts/lato/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Verlag Book */
@font-face {
  font-family: "Verlag Book";
  src: url("./../../fonts/verlag/Verlag-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

/* Verlag Light */
@font-face {
  font-family: "Verlag";
  src: url("./../../fonts/verlag/Verlag-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

/* Verlag Extra Light */
@font-face {
  font-family: "Verlag";
  src: url("./../../fonts/verlag/Verlag-XLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

/* Verlag Bold */
@font-face {
  font-family: "Verlag";
  src: url("./../../fonts/verlag/Verlag Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

/* Verlag Black */
@font-face {
  font-family: "Verlag";
  src: url("./../../fonts/verlag/Verlag-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* Verlag Light Italic */
@font-face {
  font-family: "Verlag italic";
  src: url("./../../fonts/verlag/Verlag-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

/* Verlag Black Italic */
@font-face {
  font-family: "Verlag";
  src: url("./../../fonts/verlag/Verlag-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

/* Verlag Condensed Book */
@font-face {
  font-family: "Verlag Condensed";
  src: url("./../../fonts/verlag/VerlagCondensed-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

/* Verlag Condensed Light */
@font-face {
  font-family: "Verlag Condensed";
  src: url("./../../fonts/verlag/VerlagCondensed-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

/* Verlag Condensed Extra Light */
@font-face {
  font-family: "Verlag Condensed";
  src: url("./../../fonts/verlag/VerlagCondensed-XLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
