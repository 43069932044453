.wrapper {
  background: #eaeaea;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin-top: -0.5rem;
  padding-bottom: 1.2rem;
  padding-top: 0.3rem;
}

.title {
  padding: 0.6rem;
}
