.deactive {
  img {
    opacity: 0.5;
    filter: grayscale(1);
  }
}

.hiddenBadge {
  background: #232323;
  color: #fff;
  padding: 0.25rem;
  font-size: 0.75rem;
  text-align: center;
  font-style: italic;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.newBadge {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  font-style: italic;
  font-size: 0.75rem;
}
