@import "./../../../../assets/styles/global/colors";

.header {
  background-color: $secondary !important;

  p {
    color: #fff;
  }

  svg {
    fill: #fff;
  }
}

.wrapper {
  padding: 0 !important;
}

.table {
  thead {
    th {
      background-color: #232323;
      color: #fff;
    }
  }
}
