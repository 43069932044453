.radioLabel,
.switchLabel {
  transform: translate(0, -1.5px) scale(0.75);
  transform-origin: 0 0;
  margin-bottom: -0.65rem;
}

.textField {
  input {
    color: #000 !important;
    -webkit-text-fill-color: #000 !important;
  }
}
