.thead {
  th {
    background: #000;
    color: #fff;
    text-transform: uppercase;
  }
}

.tFoot {
  td {
    background: #f1f1f1;

    span {
      font-weight: 900;
    }
  }
}

.nameWrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.6rem;
  margin-right: -0.6rem;

  p {
    padding: 0.6rem;
  }
}

.editDialogContent {
  :global {
    .btn-form-submit {
      position: absolute;
      right: 1.2rem;
      bottom: 0.9rem;
    }
  }
}

.chip {
  margin: 0.15rem;
  border-radius: 0.3rem !important;
}
