@import "./../../../../assets/styles/global/colors";

.card {
  width: 100%;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-bottom: 7rem;
  }
}

.cardIn {
  position: relative;
  padding-left: 3rem;
}

.cardContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  background-color: $secondary--light;
  color: $secondary;
  margin-bottom: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;

  svg {
    font-size: 1.5rem;
    // filter: invert(51%) sepia(76%) saturate(450%) hue-rotate(135deg)
    //   brightness(103%) contrast(81%);
    display: block;
    width: 1.5rem;
  }
}

.title {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

.subtitle {
  // font-size: 1rem !important;
  text-transform: none !important;
  line-height: normal !important;
}

@media (min-width: 768px) {
  .title {
    font-size: 1.2rem !important;
  }

  .card {
    &::before {
      padding-bottom: 100%;
    }
  }

  .cardIn {
    padding-left: 0;
  }

  .icon {
    position: relative;
    width: 3rem;
    height: 3rem;
  }
}

@media (min-width: 1200px) {
  .cardContent {
    justify-content: flex-start;
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0.5rem;

    svg {
      font-size: 1.5rem;
    }
  }

  .title {
    font-size: 1rem !important;
    line-height: normal !important;
  }

  .subtitle {
    font-size: 0.8rem !important;
  }

  .cardIn {
    height: 100%;
    justify-content: space-between;
  }
}

@media (min-width: 1600px) {
  .cardContent {
    justify-content: center;
  }

  .icon {
    width: 4em;
    height: 4em;
    margin-bottom: 1rem;

    svg {
      font-size: 2.25rem;
    }
  }

  .title {
    font-size: 1.2rem !important;
    line-height: normal !important;
  }

  .subtitle {
    font-size: 1rem !important;
  }

  .cardIn {
    // justify-content: center;
    padding: 20% 0;
  }
}
