.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.8rem;

  li {
    width: 50%;

    button {
      margin-right: 1.2rem;
    }
  }
}
