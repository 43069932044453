@import "./../../../../../assets/styles/global/colors";

.itemLink {
  text-decoration: none;
  position: "relative";
  display: "block";
  color: #232323;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $secondary;
  }
}

.itemTotal {
  display: block;
  margin-top: 0.3rem;
  font-size: 0.8rem;
  opacity: 0.8;
}
