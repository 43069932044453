.tableWrap {
  padding-top: 1.2rem !important;

  table {
    border-radius: 0;

    thead {
      tr {
        th {
          border-radius: 0;
          background: #232323;
          text-transform: uppercase;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
}
