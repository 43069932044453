.navlink {
  a,
  span {
    font-size: 0.8rem;
  }
}

.navlist {
  span {
    font-size: 0.6rem;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }
}
