@import "./../../../../../../assets/styles/global/colors";

.tableWrap {
  padding-top: 1.2rem !important;

  table {
    border-radius: 0;

    thead {
      tr {
        th {
          border-radius: 0;
          background: #232323;
          text-transform: uppercase;
          font-weight: 600;
          color: #fff;
          text-align: center;

          &:not(:first-child) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          &:first-child {
            min-width: 12.5rem;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          &:not(:first-child) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          &:first-child {
            input {
              text-align: left;
            }
          }

          input {
            font-size: 0.8rem;
            line-height: 1;
            padding: 0.5rem;
            text-align: center;
          }

          label {
            font-size: 0.8rem;
            padding: 0;
          }
        }
      }
    }
  }
}

.tabPanel {
  padding: 2.4rem 0 0 0 !important;

  h5 {
    font-size: 0.85rem;
    font-weight: 600;
  }
}

.autoCompleteOption {
  font-size: 0.8rem;
  padding: 0.5rem;
}

.tabs {
  margin-left: -0.2rem;
  margin-right: -0.2rem;
  padding-top: 1.2rem;
}

.tab {
  background: #fff !important;
  color: $secondary !important;
  border-radius: 0.25rem !important;
  border: 1px solid $secondary !important;
  margin: 0.2rem !important;
}

.tabSelected {
  color: #fff !important;
  background: $secondary !important;
}
