@import "./../../assets/styles/app.scss";

.wrapper {
  background: linear-gradient(0deg, $primary, $secondary);
  min-height: 100vh;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  background-size: 200% 200%;
  animation: bgAnimation 30s ease infinite;
}

@keyframes bgAnimation {
  0% {
    background-position: 78% 0%;
  }
  50% {
    background-position: 23% 100%;
  }
  100% {
    background-position: 78% 0%;
  }
}

.logo {
  display: block;
  margin: 0 auto 2.4rem auto;
}

.cardContent {
  display: flex;
  padding: 0 !important;
}

.cardImage {
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.outletWrapper {
  padding: 2.4rem;
}

@media (min-width: 1200px) {
  .contentWrapper {
    min-width: 700px;
  }

  .copyright {
    position: fixed;
    bottom: 1.2rem;
    left: 0;
    right: 0;
    margin: auto;
  }

  .outletWrapper {
    padding: 2.8rem 2.6rem 2.4rem 2.6rem;
  }
}

@media (min-width: 1440px) {
  .contentWrapper {
    min-width: 720px;
  }

  .outletWrapper {
    padding: 3.6rem 3.2rem 3rem 3.2rem;
  }
}

// /* Sales Agent Login */

// position: relative;
// width: 1920px;
// height: 1080px;

//
// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

// /* form */

// position: absolute;
// width: 900px;
// height: 649px;
// left: 510px;
// top: 215px;

// filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

// /* Paper

// The elevation can be used to establish a hierarchy between other content. In practical terms, the elevation controls the size of the shadow applied to the surface. In dark mode, raising the elevation also makes the surface lighter.
// */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;
// gap: 8px;

// position: absolute;
// width: 900px;
// height: 550px;
// left: 510px;
// top: 314px;

// /* Light/Background/Paper */
// background: #FFFFFF;
// /* Elevation/3 */
// box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
// border-radius: 8px 0px 0px 8px;

// /* form content */

// position: absolute;
// width: 350px;
// height: 375px;
// left: 981px;
// top: 402px;

// /* Text Field/Standard

// Text fields let users enter and edit text.
// */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;
// gap: 3px;

// position: absolute;
// width: 350px;
// height: 48px;
// left: 981px;
// top: 508px;

// /* Input */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;
// gap: 5.5px;

// width: 350px;
// height: 48px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// align-self: stretch;
// flex-grow: 0;

// /* _tempLabel */

// width: 62px;
// height: 12px;

// /* Components/Input Label */
// font-family: 'Roboto';
// font-style: normal;
// font-weight: 400;
// font-size: 12px;
// line-height: 12px;
// /* identical to box height, or 100% */
// display: flex;
// align-items: center;
// letter-spacing: 0.15px;

// /* Light/Text/Secondary */
// color: rgba(0, 0, 0, 0.6);

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Label */

// width: 350px;
// height: 24px;

// /* Components/Input Text */
// font-family: 'Lato';
// font-style: normal;
// font-weight: 400;
// font-size: 16px;
// line-height: 24px;
// /* identical to box height, or 150% */
// display: flex;
// align-items: center;
// letter-spacing: 0.15px;

// /* Light/Text/Secondary */
// color: rgba(0, 0, 0, 0.6);

// /* Inside auto layout */
// flex: none;
// order: 1;
// align-self: stretch;
// flex-grow: 0;

// /* Underline */

// width: 350px;
// height: 1px;

// /* Inside auto layout */
// flex: none;
// order: 2;
// align-self: stretch;
// flex-grow: 0;

// /* Rename */

// position: absolute;
// height: 0px;
// left: 0%;
// right: 0%;
// bottom: 0px;

// /* Light/Other/Standard Input Line */
// border: 1px solid rgba(0, 0, 0, 0.42);

// /* Active */

// position: absolute;
// visibility: hidden;
// height: 0px;
// left: 0%;
// right: 0%;
// bottom: 0px;

// /* Grey/black */
// border: 2px solid #000000;

// /* Text Field/Standard

// Text fields let users enter and edit text.
// */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;
// gap: 3px;

// position: absolute;
// width: 350px;
// height: 30.5px;
// left: 981px;
// top: 598px;

// /* Input */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;
// gap: 5.5px;

// width: 350px;
// height: 30.5px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// align-self: stretch;
// flex-grow: 0;

// /* Label */

// display: none;
// width: 55px;
// height: 12px;

// /* Components/Input Label */
// font-family: 'Roboto';
// font-style: normal;
// font-weight: 400;
// font-size: 12px;
// line-height: 12px;
// /* identical to box height, or 100% */
// display: flex;
// align-items: center;
// letter-spacing: 0.15px;

// /* Light/Text/Secondary */
// color: rgba(0, 0, 0, 0.6);

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Content */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// align-items: flex-start;
// padding: 0px;
// gap: 8px;

// width: 350px;
// height: 24px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// align-self: stretch;
// flex-grow: 0;

// /* Value */

// width: 318px;
// height: 24px;

// /* Components/Input Text */
// font-family: 'Lato';
// font-style: normal;
// font-weight: 400;
// font-size: 16px;
// line-height: 24px;
// /* identical to box height, or 150% */
// letter-spacing: 0.15px;

// color: rgba(0, 0, 0, 0.6);

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 1;

// /* Adornment */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// align-items: center;
// padding: 0px;
// gap: 10px;

// width: 24px;
// height: 24px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* RemoveRedEyeFilled */

// width: 24px;
// height: 24px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Vector */

// position: absolute;
// left: 4.17%;
// right: 4.17%;
// top: 18.75%;
// bottom: 18.75%;

// /* Light/Action/Active (54p) */
// background: rgba(0, 0, 0, 0.54);

// /* Underline */

// width: 350px;
// height: 1px;

// /* Inside auto layout */
// flex: none;
// order: 2;
// align-self: stretch;
// flex-grow: 0;

// /* Rename */

// position: absolute;
// height: 0px;
// left: 0%;
// right: 0%;
// bottom: 0px;

// /* Light/Other/Standard Input Line */
// border: 1px solid rgba(0, 0, 0, 0.42);

// /* Active */

// position: absolute;
// visibility: hidden;
// height: 0px;
// left: 0%;
// right: 0%;
// bottom: 0px;

// /* Grey/black */
// border: 2px solid #000000;

// /* Button/Text

// Buttons allow users to take actions, and make choices, with a single tap.
// */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
// padding: 0px;

// position: absolute;
// width: 141px;
// height: 30px;
// left: 981px;
// top: 747px;

// border-radius: 4px;

// /* UnstyledButton */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 4px 5px;
// gap: 8px;

// width: 141px;
// height: 30px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Button */

// width: 131px;
// height: 22px;

// font-family: 'Lato';
// font-style: normal;
// font-weight: 500;
// font-size: 12px;
// line-height: 22px;
// /* identical to box height, or 183% */
// letter-spacing: 0.46px;
// text-transform: uppercase;

// color: #512879;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Button/Contained

// Buttons allow users to take actions, and make choices, with a single tap.
// */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
// padding: 0px;

// position: absolute;
// width: 350px;
// height: 42px;
// left: 981px;
// top: 670px;

// background: #12830b;
// /* Elevation/2 */
// box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
// border-radius: 4px;

// /* UnstyledButton */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 8px 22px;
// gap: 8px;

// width: 102px;
// height: 42px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Button */

// width: 58px;
// height: 26px;

// font-family: 'Lato';
// font-style: normal;
// font-weight: 500;
// font-size: 15px;
// line-height: 26px;
// /* identical to box height, or 173% */
// letter-spacing: 0.46px;
// text-transform: uppercase;

// /* Light/Primary/Contrast */
// color: #FFFFFF;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* page_header */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;
// gap: 5px;

// position: absolute;
// width: 323px;
// height: 74px;
// left: 981px;
// top: 402px;

// /* Typography

// Use typography to present your design and content as clearly and efficiently as possible.
// */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;

// width: 226px;
// height: 41px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Typography */

// width: 226px;
// height: 41px;

// font-family: 'Lato';
// font-style: normal;
// font-weight: 500;
// font-size: 34px;
// line-height: 120%;
// /* identical to box height, or 41px */
// letter-spacing: -0.5px;

// /* Light/Text/Primary */
// color: rgba(0, 0, 0, 0.87);

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Breadcrumbs

// Breadcrumbs consist of a list of links that help a user visualize a page's location within the hierarchical structure of a website, and allow navigation up to any of its "ancestors".
// */

// /* Auto layout */
// display: flex;
// flex-direction: row;
// align-items: center;
// padding: 0px;

// display: none;
// width: 445px;
// height: 24px;

// /* Inside auto layout */
// flex: none;
// order: 1;
// flex-grow: 0;

// /* Typography

// Use typography to present your design and content as clearly and efficiently as possible.
// */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;

// width: 323px;
// height: 28px;

// /* Inside auto layout */
// flex: none;
// order: 2;
// flex-grow: 0;

// /* Typography */

// width: 323px;
// height: 28px;

// font-family: 'Lato';
// font-style: normal;
// font-weight: 400;
// font-size: 16px;
// line-height: 175%;
// /* identical to box height, or 28px */
// letter-spacing: 0.15px;

// /* Light/Text/Primary */
// color: rgba(0, 0, 0, 0.87);

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* bg */

// position: absolute;
// width: 400px;
// height: 550px;
// left: 510px;
// top: 314px;

// /* Rectangle 6 */

// position: absolute;
// left: 0%;
// right: 0%;
// top: 0%;
// bottom: 0%;

// background: #5E5E5E;

// /* Rectangle 7 */

// position: absolute;
// left: 0%;
// right: 0%;
// top: 0%;
// bottom: 0%;

// background: #000000;

// /* see-target-african-american-young-basketball-player-red-team-action-neon-lights-dark-studio-background-concept-sport-movement-energy-dynamic-healthy-lifestyle 1 */

// position: absolute;
// visibility: hidden;
// left: 35.73%;
// right: 42.81%;
// top: -25%;
// bottom: -20.6%;

// background: url(see-target-african-american-young-basketball-player-red-team-action-neon-lights-dark-studio-background-concept-sport-movement-energy-dynamic-healthy-lifestyle);
// transform: matrix(-1, 0, 0, 1, 0, 0);

// /* mysterious-nature-african-american-young-basketball-player-red-team-action-neon-lights-dark-studio-background-concept-sport-movement-energy-dynamic-healthy-lifestyle 1 */

// position: absolute;
// left: -50%;
// right: 0%;
// top: -16.91%;
// bottom: -36.91%;

// background: url(mysterious-nature-african-american-young-basketball-player-red-team-action-neon-lights-dark-studio-background-concept-sport-movement-energy-dynamic-healthy-lifestyle.jpg);

// /* Rectangle 8 */

// position: absolute;
// left: 0%;
// right: 0%;
// top: 0%;
// bottom: 0%;

// background: linear-gradient(89.92deg, #512879 0.12%, #12830b 99.98%);
// mix-blend-mode: hue;

// /* Rectangle 18 */

// position: absolute;
// width: 100px;
// height: 56.25px;
// left: 910px;
// top: 215px;

// background: url(logo_black.png);
// mix-blend-mode: normal;

// /* Typography

// Use typography to present your design and content as clearly and efficiently as possible.
// */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: flex-start;
// padding: 0px;

// position: absolute;
// width: 192px;
// height: 20px;
// left: 864px;
// top: 1024px;

// /* Typography */

// width: 192px;
// height: 20px;

// /* Typography/Caption */
// font-family: 'Roboto';
// font-style: normal;
// font-weight: 400;
// font-size: 12px;
// line-height: 166%;
// /* identical to box height, or 20px */
// text-align: center;
// letter-spacing: 0.4px;

// /* Light/Text/Primary */
// color: rgba(0, 0, 0, 0.87);

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;
