@import "./../../../assets/styles/global/colors";

.breadcrumbs {
  margin-top: 1rem;
  font-size: 0.85rem !important;

  p,
  a,
  svg {
    font-size: 0.85rem !important;
  }

  a {
    text-decoration: none;
    opacity: 0.7;
    color: #232323;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $secondary;
      opacity: 1;
    }
  }
}
